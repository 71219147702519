<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form
      @submit.stop.prevent="handleSubmit(onSubmit)"
      @reset.prevent="closeSalaryEditDetailsForm"
    >
      <b-row class="mb-2">
        <!-- Start Salary basic -->
        <b-col md="6" cols="12">
          <validation-provider
            #default="validationContext"
            name="Salaire"
            rules="required"
          >
            <b-form-group
              label="Salaire :"
              label-for="basic_salary"
              :state="getValidationState(validationContext)"
            >
              <b-input-group prepend="XOF" append=".00">
                <b-form-input
                  id="basic_salary"
                  v-model="details.basic_salary"
                  type="number"
                  placeholder="Salaire de base"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- End Salary basic -->

        <!-- Start Contractor Tax -->
        <b-col v-if="isContractor" md="4" cols="12">
          <validation-provider
            #default="validationContext"
            name="Taxe"
            rules="required"
          >
            <b-form-group
              :label="`Taxe Applicable : ${contractorTaxFormat}`"
              label-for="tax"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currContractorTax"
                :options="invoiceTaxesList"
                :reduce="(t) => t.slug"
                label="name"
                input-id="tax-input"
                :clearable="true"
                :selectable="(option) => option.id != null"
              >
                <template v-slot:no-options="{ search, searching }">
                  <em v-if="searching" style="opacity: 0.5"
                    >Recherche une taxe...</em
                  >
                  <template v-else>
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                </template>
                <template v-slot:option="option">
                  {{ option.name }} ({{ option.rate }}%)
                </template>
              </v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- End Contractor Tax -->

        <!-- Start CNSS Employee -->
        <b-col v-if="!isContractor" md="2" cols="12">
          <validation-provider
            #default="validationContext"
            name="CNSS Employé"
            rules="required"
          >
            <b-form-group
              label="CNSS Employé(e) :"
              label-for="cnss_percentage"
              :state="getValidationState(validationContext)"
            >
              <b-input-group append="%">
                <b-form-input
                  id="cnss_percentage"
                  :value="taxIsFor('cnss_employe').rate"
                  type="number"
                  disabled
                  placeholder="CNSS Employé"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
              <b-form-text id="employee_cnss_percentage">
                {{ amountToCurrency(employeeCnssAmount) }}
              </b-form-text>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- End CNSS Employee -->

        <!-- Start CNSS Employer -->
        <b-col v-if="!isContractor" md="2" cols="12">
          <validation-provider
            #default="validationContext"
            name="CNSS Employeur"
            rules="required"
          >
            <b-form-group
              label="CNSS Employeur:"
              label-for="employer_cnss_percentage"
              :state="getValidationState(validationContext)"
            >
              <b-input-group append="%">
                <b-form-input
                  id="employer_cnss_percentage"
                  :value="taxIsFor('cnss_employeur').rate"
                  type="number"
                  disabled
                  placeholder="CNSS Employeur"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
              <b-form-text id="password-help-block">
                {{ amountToCurrency(employerCnssAmount) }}
              </b-form-text>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- End CNSS Employer -->

        <!-- Start VPS -->
        <b-col v-if="!isContractor" md="2" cols="12">
          <validation-provider
            #default="validationContext"
            name="VPS"
            rules="required"
          >
            <b-form-group
              label="VPS :"
              label-for="vps"
              :state="getValidationState(validationContext)"
            >
              <b-input-group append="%">
                <b-form-input
                  id="vps"
                  :value="taxIsFor('vps').rate"
                  type="number"
                  disabled
                  placeholder="Pourcentage VPS"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
              <b-form-text id="password-help-block">
                {{ amountToCurrency(vps) }}
              </b-form-text>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- End VPS -->
      </b-row>
      <b-row>
        <b-col v-if="!isContractor" md="6" cols="12"
          ><b class="mr-1">Total CNSS :</b
          >{{ amountToCurrency(totalCnss) }}</b-col
        >
        <b-col v-if="!isContractor" md="6" cols="12"
          ><b class="mr-1">ITS :</b>{{ amountToCurrency(currIts) }}</b-col
        >
        <b-col class="mb-2" v-if="!isContractor" md="6" cols="12"
          ><b class="mr-1">Total Impôts :</b
          >{{ amountToCurrency(totalTaxes) }}</b-col
        >

        <b-col
          class="mb-2"
          :offset-md="isContractor ? '6' : ''"
          md="6"
          cols="12"
          ><b class="mr-1">Salaire Net :</b
          >{{ amountToCurrency(netSalary) }}</b-col
        >

        <!-- Start compensation -->
        <b-col v-if="!isContractor" md="6" cols="12">
          <validation-provider
            #default="validationContext"
            name="Indemnités"
            rules="required"
          >
            <b-form-group
              label="Indemnités :"
              label-for="total_compensation"
              :state="getValidationState(validationContext)"
            >
              <b-input-group prepend="XOF" append=".00">
                <b-form-input
                  id="total_compensation"
                  v-model="details.total_compensation"
                  type="number"
                  placeholder="Indemnités"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- End compensation -->

        <!-- Start contribution -->
        <b-col v-if="!isContractor" md="6" cols="12">
          <validation-provider
            #default="validationContext"
            name="Cotisations"
            rules="required"
          >
            <b-form-group
              label="Autres Cotisations :"
              label-for="total_contribution"
              :state="getValidationState(validationContext)"
            >
              <b-input-group prepend="XOF" append=".00">
                <b-form-input
                  id="total_contribution"
                  v-model="details.total_contribution"
                  type="number"
                  placeholder="Cotisations"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- End contribution -->

        <!-- Start Check tax existence -->
        <!-- <b-col v-show="isContractor" cols="12" class="mt-2">
          <validation-provider
            #default="validationContext"
            name="Taxe applicable"
            rules="required"
          >
            <b-form-group
              label-for="is_taxable"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                id="is_taxable"
                v-model="isTaxable"
                class="custom-control-warning"
              >
                <span class="text-bold"
                  >Taxe applicable ? {{ findTax ? findTax + "%" : "" }}</span
                >
              </b-form-checkbox>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col> -->
        <!-- End Check tax existence -->
      </b-row>
      <b-row class="mt-3">
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="invalid || isLoading"
          >
            <b-spinner v-if="isLoading" small></b-spinner>
            Mettre à jour
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
          >
            Annuler
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormText,
  BSpinner,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import store from "@/store";
import router from "@/router";
import { computed, ref, watch } from "@vue/composition-api";
import { amountToCurrency } from "@/utils/formatAmount";

export default {
  name: "EmployeeSalaryStatementEditDetails",
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormText,
    BSpinner,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BForm,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    salaryDetails: {
      type: Object,
      required: true,
    },
    taxOptions: {
      type: Array,
      required: true,
    },
    isContractor: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const details = ref(JSON.parse(JSON.stringify(props.salaryDetails)));
    const currContractorTax = ref(null);
    const toast = useToast();
    const tranches = [
      {
        min: 0,
        max: 60000,
        rate: 0,
      },
      {
        min: 60001,
        max: 150000,
        rate: 0.10,
      },
      {
        min: 150001,
        max: 250000,
        rate: 0.15,
      },
      {
        min: 250001,
        max: 500000,
        rate: 0.19,
      },
      {
        min: 500001,
        max: Infinity,
        rate: 0.30,
      },
    ];

    // Invoice Taxes
    const invoiceTaxesList = computed(() => {
      return props.taxOptions.filter((t) => t.type == "facture");
    });

    // Salary Taxes
    const salaryTaxesList = computed(() => {
      return props.taxOptions.filter((t) => t.type == "salaire");
    });

    // Employee CNSS
    const employeeCnssAmount = computed(() => {
      const cnssEmployeeVal = taxIsFor("cnss_employe").rate;
      if (cnssEmployeeVal <= 0) return 0;
      return (details.value.basic_salary * cnssEmployeeVal) / 100;
    });

    // Employer CNSS
    const employerCnssAmount = computed(() => {
      const cnssEmployerVal = taxIsFor("cnss_employeur").rate;
      if (cnssEmployerVal <= 0) return 0;
      return (details.value.basic_salary * cnssEmployerVal) / 100;
    });

    // VPS
    const vps = computed(() => {
      const vpsVal = taxIsFor("vps").rate;
      if (vpsVal <= 0) return 0;
      return (details.value.basic_salary * vpsVal) / 100;
    });

    // Total CNSS
    const totalCnss = computed(() => {
      return employeeCnssAmount.value + employerCnssAmount.value;
    });

    // ITS
    const currIts = computed(() => {
      const roundVal = 1;
      let initialSalary = details.value.basic_salary; // convert salary to number

      const roundSalary = Math.round(initialSalary / roundVal) * roundVal; // round salary at "roundVal"

      // check if the round salary is greater than salary and then substract the round val
      // if not keep the round salary value
      if (roundSalary > initialSalary) initialSalary = roundSalary - roundVal;
      else initialSalary = roundSalary;

      // calculate tax
      let taxAmount = 0;
      let remainSalary = initialSalary;

      for (let i = 0; i < tranches.length; i++) {
        if (remainSalary <= 0) break;

        const tranche = tranches[i];
        const trancheAmount = Math.min(
          remainSalary,
          tranche.max - (i > 0 ? tranches[i - 1].max : 0)
        );
        
        taxAmount += (trancheAmount * tranche.rate); // get tax for the current tranche and add it to the tax amount
        remainSalary -= trancheAmount; // substract the tranch amount then the next iteration would check with the remain salary
      }
      return taxAmount;
    });

    // Total Taxes
    const totalTaxes = computed(() => {
      return currIts.value + vps.value;
    });

    // Net Salary
    const netSalary = computed(() => {
      let deduction = 0;

      if (props.isContractor) {
        if (findContractorTax() != undefined) {
          deduction = (details.value.basic_salary * findContractorTax().rate) / 100;
        }
      } else {
        deduction = employeeCnssAmount.value + currIts.value;
      }

      return details.value.basic_salary - deduction;
    });

    const findContractorTax = () => {
      return invoiceTaxesList.value.find(
        (t) => t.slug == currContractorTax.value
      );
    };

    const contractorTaxFormat = computed(() => {
      if (findContractorTax() != undefined) {
        const amount = (details.value.basic_salary * findContractorTax().rate) / 100; // get correct amount based on tax
        return (
          findContractorTax().name +
          " (" +
          findContractorTax().rate +
          "%) - " +
          amountToCurrency(amount)
        );
      }
      return "";
    });

    // Check to which tax is the field provide is belong to.
    // This allow user to get employee cnss, employer cnss, vps, etc.
    const taxIsFor = (field) => {
      const currSalaryTax = salaryTaxesList.value.find((t) => t.slug == field);

      if (currSalaryTax == undefined)
        return {
          name: "",
          code: "",
          rate: 0,
        };
      return currSalaryTax;
    };

    const resetDetails = () =>
      (details.value = JSON.parse(JSON.stringify(props.salaryDetails)));

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetDetails);

    const closeSalaryEditDetailsForm = () => {
      emit("salaryDetailsUpdated");
      resetForm();
    };

    const onSubmit = () => {
      isLoading.value = true
      let {
        basic_salary,
        total_compensation,
        total_contribution,
      } = details.value;

      const payload = {
        uid: router.currentRoute.params.id,
        details: {
          salary: parseInt(basic_salary),
          is_contractor: props.isContractor,
          employee_tax: taxIsFor("cnss_employe").slug,
          employer_tax: taxIsFor("cnss_employeur").slug,
          contractor_tax: currContractorTax.value,
          vps_tax: taxIsFor("vps").slug,
          total_compensation: parseInt(total_compensation),
          total_contribution: parseInt(total_contribution),
        },
      };

      store
        .dispatch("employee/updateSalaryDetails", payload)
        .then((response) => {
          if (response.success) {
            closeSalaryEditDetailsForm();
            toast({
              component: ToastificationContent,
              props: {
                title: "État de salaire mise à jour avec succès.",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            refFormObserver.value.setErrors({ ...response.errors });
            toast({
              component: ToastificationContent,
              props: {
                title: "Problème de la mise à jour de l'État de salaire",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          isLoading.value = false
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title:
                "Une erreur est survenue lors de la mise à jour de l'État de paiement.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          isLoading.value = false
        });
    };

    return {
      currContractorTax,
      details,
      onSubmit,
      taxIsFor,
      resetDetails,
      refFormObserver,
      salaryTaxesList,
      invoiceTaxesList,
      getValidationState,
      closeSalaryEditDetailsForm,
      amountToCurrency,

      isLoading,
      contractorTaxFormat,

      employeeCnssAmount,
      employerCnssAmount,
      vps,
      totalCnss,
      currIts,
      totalTaxes,
      netSalary,
    };
  },
};
</script>
