export const amountToCurrency = (
  amount = 0,
  locale = "fr-FR",
  currency = "XOF"
) => {
  if (!amount) amount = 0;
  if (typeof amount === "string") amount = parseFloat(amount);
  let options = {};
  if (currency)
    options = {
      style: "currency",
      currency: currency,
    };
  return amount.toLocaleString(locale, options);
};

export const _ = null;
