<template>
  <div>
    <!-- Miscellaneous Charts -->
    <b-row class="match-height">
      <b-col lg="2" cols="6">
        <statistic-card-vertical
          icon="EyeIcon"
          :statistic="amountToCurrency(salary)"
          statistic-title="Brut"
          color="info"
          title-size="5"
        />
      </b-col>
      <b-col lg="10" cols="12">
        <employee-salary-statement-status-card
          v-if="employeeData"
          :salary-details="employeeData.salary_details"
          :taxOptions="taxOptions"
          @goBack="$router.push({ name: 'apps-employees-view', params: { id: $router.currentRoute.params.id } })"
        />
      </b-col>
    </b-row>
    <!--/ Miscellaneous Charts -->

    <!-- Update Salary details -->
    <b-button
      v-if="!toggleSalaryDetailsEditForm"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      @click="toggleSalaryDetailsEditForm = true"
    >
      <feather-icon icon="Edit3Icon" class="mr-50" />
      <span class="align-middle">Mettre à jour</span>
    </b-button>

    <b-row
      v-if="employeeData && toggleSalaryDetailsEditForm"
      class="match-height"
    >
      <b-col cols="12">
        <b-card no-body>
          <b-card-header class="mb-2">
            <b-card-title>Mise à jour des détails de salaire</b-card-title>
            <b-card-text class="mr-25 mb-0">
              <i
                ><span class="text-danger">*</span>Les informations affichées
                ici une fois modifées impacteront le prochain paiement de
                salaire</i
              >
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <employee-salary-statement-edit-details
              :salary-details="employeeData.salary_details"
              :isContractor="employeeData.employee_type == 'contractor'"
              :taxOptions=taxOptions
              @salaryDetailsUpdated="toggleSalaryDetailsEditForm = false"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!--/ Update salary details -->
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import EmployeeSalaryStatementStatusCard from "./EmployeeSalaryStatementStatusCard.vue";
import EmployeeSalaryStatementEditDetails from "./EmployeeSalaryStatementEditDetails.vue";
import { computed, ref, watch, onMounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import useEmployeesList from "../employees-list/useEmployeesList";

import { amountToCurrency } from "@/utils/formatAmount";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BButton,
    StatisticCardVertical,
    EmployeeSalaryStatementStatusCard,
    EmployeeSalaryStatementEditDetails,
  },
  directives: {
    Ripple,
  },
  setup() {
    const employeeData = ref(null);
    const toggleSalaryDetailsEditForm = ref(false);

    const {
      // data
      getWagesHistories,
      taxOptions,
      fetchEmployeeProperties,
    } = useEmployeesList();
    

    const salary = computed(() => {
      return employeeData.value ? employeeData.value.salary : 0;
    });

    const getSalaryDetails = () => {
      store
        .dispatch("employee/fetchEmployee", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          employeeData.value = response;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            employeeData.value = undefined;
          }
        });
    };

    getSalaryDetails();

    watch(toggleSalaryDetailsEditForm, (newVal) => {
      getSalaryDetails();
    });

    onMounted(() => {
      let props = ["taxes"];
      fetchEmployeeProperties(props);
    });

    return {
      employeeData,
      salary,
      taxOptions,
      amountToCurrency,
      getWagesHistories,
      toggleSalaryDetailsEditForm,
    };
  },
};
</script>
