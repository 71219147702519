import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";
import { title, formatDate } from "@core/utils/filter";
import { amountToCurrency } from "@/utils/formatAmount";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useEmployeesList() {
  // Use toast
  const toast = useToast();
  const { route, router } = useRouter();

  const refEmployeeListTable = ref(null);
  const refWagesHistoryListTable = ref(null);
  const refLeavesHistoryListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "user", label: "Personnel", sortable: true },
    { key: "email", sortable: true },
    {
      key: "department",
      label: "Département",
      formatter: (val) => (val != null ? title(val.name) : ""),
      sortable: true,
    },
    {
      key: "service",
      label: "Service",
      formatter: (val) => (val != null ? title(val.name) : ""),
      sortable: true,
    },
    { key: "status", label: "Statut", sortable: true },
    { key: "actions" },
  ];
  const wagesHistoryFields = [
    { key: "basic_salary",
      label: "Brut",
      formatter: (val) => amountToCurrency(val),
    },
    { key: "deduction", label: "Mutuel", formatter: (val) => amountToCurrency(val) },
    { key: "bonus", label: "Prime", formatter: (val) => amountToCurrency(val) },
    { key: "net_salary", label: "Net", formatter: (val) => amountToCurrency(val) },
    { key: "details", label: "Période", formatter: (val) => val.concerned_month },
    {
      key: "paid_by",
      label: "Payé Par",
      formatter: (val) => (val != null ? title(val.name) : ""),
      sortable: true,
    },
    // { key: "actions" },
  ];
  const leavesHistoryFields = [
    { key: "from", label: "Du", formatter: (val) => formatDate(val), sortable: true },
    { key: "to", label: "Au", formatter: (val) => formatDate(val),  sortable: true },
    { key: "days_number", label: "Nbr. jours", formatter: (val) => `${val} jour(s)`,  sortable: true },
    {
      key: "motive",
      label: "Motif",
    },
    { key: "created_by", label: "Ajouté par", formatter: (val) => val.name },
    // { key: "actions" },
  ];

  // items per page
  const perPage = ref(10);
  const whPerPage = ref(10);
  const lhPerPage = ref(10);

  // total items pagination
  const totalEmployees = ref(0);
  const totalWagesHistories = ref(0);
  const totalLeavesHistories = ref(0);

  // current page
  const currentPage = ref(1);
  const whCurrentPage = ref(1);
  const lhCurrentPage = ref(1);

  // page options
  const perPageOptions = [10, 25, 50, 100];
  const whPageOptions = [10, 25, 50, 100];
  const lhPageOptions = [10, 25, 50, 100];

  // search query
  const searchQuery = ref("");
  const whSearchQuery = ref("");
  const lhSearchQuery = ref("");

  // sort by
  const sortBy = ref("id");
  const whSortBy = ref("id");
  const lhSortBy = ref("id");

  // sort direction
  const isSortDirDesc = ref(true);
  const whSortDirDesc = ref(true);
  const lhSortDirDesc = ref(true);

  const whSortDirection = ref("asc");
  const lhSortDirection = ref("asc");

  const departmentOptions = ref([]);
  const officeOptions = ref([]);
  const taxOptions = ref([]);
  const genderOptions = ref([
    { text: "Homme", value: "male" },
    { text: "Femme", value: "female" },
  ]);
  const contractOptions = ref([
    { label: "Sélectionner un contrat", value: "nothing_selected" },
    { label: "CDD", value: "cdd" },
    { label: "CDI", value: "cdi" },
  ]);

  // Filter
  const departmentFilter = ref("");
  const contractFilter = ref("");
  const whFilter = ref("");
  const lhFilter = ref("");

  const dataMeta = computed(() => {
    const localItemsCount = refEmployeeListTable.value
      ? refEmployeeListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEmployees.value,
    };
  });

  // payment method
  const paymentMethods = [
    {
      label: "Espèce",
      value: 'cash'
    },
    {
      label: "Chèque",
      value: 'bank'
    },
    {
      label: "Transfert Mobile",
      value: 'mobile'
    },
  ];

  const refetchData = () => {
    refEmployeeListTable.value.refresh();
  };

  const refetchWagesData = () => {
    refWagesHistoryListTable.value.$refs.refListTable.refresh();
  };

  const refetchLeavesData = () => {
    refLeavesHistoryListTable.value.$refs.refListTable.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, departmentFilter, contractFilter],
    () => {
      refetchData();
    }
  );

  watch(
    [whCurrentPage, whPerPage, whSearchQuery, whFilter],
    () => {
      refetchWagesData();
    }
  );

  watch(
    [lhCurrentPage, lhPerPage, lhSearchQuery, lhFilter],
    () => {
      refetchLeavesData();
    }
  );

  // get all employee's properties to create or edit an employee
  const fetchEmployeeProperties = (properties) => {
    let params = {
      props: properties,
    };
    store
      .dispatch("employee/fetchProperties", params)
      .then((response) => {
        if(response.hasOwnProperty('departments')) {
          let departments = [
            { name: "Sélectionner un département", uid: null }
          ]
          departmentOptions.value = departments.concat(response.departments);
        }
        if(response.hasOwnProperty('taxes')) {
          taxOptions.value = response.taxes;
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur de récupération des données (Employés)",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchEmployees = (ctx, callback) => {
    store
      .dispatch("employee/fetchEmployees", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        department: departmentFilter.value,
        contract: contractFilter.value,
      })
      .then((data) => {
        const { employees, total } = data;

        callback(employees);
        totalEmployees.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur de récupération de la liste du personnel",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const getWagesHistories = (ctx, callback) => {
    const params = {
      uid: router.currentRoute.params.id,
      queryParams: {
        filter: ctx.filter,
        perPage: ctx.perPage,
        page: ctx.currentPage,
      },
    }
    store
      .dispatch("employee/fetchWagesHistories", params)
      .then(({ wages, total }) => {
        callback(wages);
        totalWagesHistories.value = total;
      }).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur de récupération de la liste des salaires",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const getLeavesHistories = (ctx, callback) => {
    const params = {
      uid: router.currentRoute.params.id,
      queryParams: {
        filter: ctx.filter,
        perPage: ctx.perPage,
        page: ctx.currentPage,
      },
    }
    store
      .dispatch("employee/fetchLeavesHistories", params)
      .then(({ leaves, total }) => {
        callback(leaves);
        totalLeavesHistories.value = total;
      }).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur de récupération de la liste des congés",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const getOfficeOptions = (uid) => {
    if (uid == undefined || uid == null) officeOptions.value = [];
    else {
      let depart_opt = departmentOptions.value.find((o) => o.uid == uid);
      officeOptions.value = depart_opt != undefined ? depart_opt.offices : [];
    }
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveEmployeeStatusVariant = (status) => {
    if (status === "cdd") return "warning";
    if (status === "cdi") return "success";
    return "primary";
  };

  return {
    // data
    fetchEmployees,
    fetchEmployeeProperties,
    getWagesHistories,
    getLeavesHistories,
    getOfficeOptions,

    // table options
    tableColumns,
    wagesHistoryFields,
    leavesHistoryFields,

    perPage,
    whPerPage,
    lhPerPage,

    currentPage,
    whCurrentPage,
    lhCurrentPage,

    totalEmployees,
    totalWagesHistories,
    totalLeavesHistories,
    dataMeta,
    paymentMethods,

    perPageOptions,
    whPageOptions,
    lhPageOptions,

    searchQuery,
    whSearchQuery,
    lhSearchQuery,

    sortBy,
    whSortBy,
    lhSortBy,

    isSortDirDesc,
    whSortDirDesc,
    lhSortDirDesc,
    whSortDirection,
    lhSortDirection,
    refEmployeeListTable,

    resolveEmployeeStatusVariant,
    refWagesHistoryListTable,
    refLeavesHistoryListTable,
    refetchData,
    refetchWagesData,
    refetchLeavesData,

    // Extra Filters
    departmentOptions,
    officeOptions,
    taxOptions,
    contractOptions,
    genderOptions,
    departmentFilter,
    contractFilter,
    whFilter,
    lhFilter,
  };
}
